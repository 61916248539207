import { Box, styled } from "@mui/material";

const StyledCustomTopBarBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  height: 45,
  fontSize: 13,
  maxWidth: 270,
  fontWeight: 500,
  padding: "0 1rem",
  borderRadius: "8px",
  border: "1px solid ",
  borderColor:
    theme.palette.mode === "light"
      ? theme.palette.divider
      : theme.palette.divider,

  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down(500)]: { maxWidth: "100%" },
}));

export default StyledCustomTopBarBox;
