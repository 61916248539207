/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from "react";
import { deepDerefrencer } from "../../../utils/form_factory";
import { ukoTheme } from "../../../theme";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Typography } from "@mui/material";
import FlexBox from "../../FlexBox";
import { SystemCurrenciesList } from "../../currency/Currencies";
import { CURRENCY_CODES } from "../../../constants/currency-codes";

interface IFormPhoneInputProps {
  name: string;
  label: string;
  formControl: any;
  countryCode?: string;
  setPhoneCode?: (code: any) => void;
}

const FormPhoneInput: FC<IFormPhoneInputProps> = ({
  name,
  label,
  formControl,
  countryCode,
  setPhoneCode,
}) => {
  const theme = ukoTheme();
  const [formattedValue, setFormattedValue] = React.useState<string>("");
  const currenciesList: any = SystemCurrenciesList;
  const preferredCountries = CURRENCY_CODES?.filter(
    (code: any) => currenciesList.indexOf(code.currency_code) >= 0,
  )?.map((code: any) => code?.country_code?.toLowerCase());

  const isFieldRequired = () => {
    return String(formControl.getFieldMeta(name)?.error).match(/required/gi)
      ? true
      : false;
  };

  useEffect(() => {
    setFormattedValue(deepDerefrencer(formControl.values, name));
  }, [name, deepDerefrencer(formControl.values, name)]);

  const handlePhoneChange = (phone: string, country: any) => {
    setPhoneCode &&
      setPhoneCode(String(country?.countryCode).toLocaleUpperCase());
    const numericValue = phone.replace(/\D/g, "");
    setFormattedValue(numericValue);

    const phoneInput = numericValue.replace(/\s/g, "").replace(/-/g, "");
    formControl.setFieldValue(name, phoneInput);
  };

  const FormError = () => {
    return (
      <>
        {deepDerefrencer(formControl.errors, name) && (
          <>
            {" "}
            <Typography color={"error"}>
              {" "}
              {deepDerefrencer(formControl.errors, name)}{" "}
            </Typography>{" "}
          </>
        )}
      </>
    );
  };

  return (
    <FlexBox flexDirection={"column"} gap={1} width={"100%"}>
      <PhoneInput
        country={countryCode || "UG"}
        specialLabel={`${label}`}
        value={formattedValue}
        enableSearch={true}
        // autoFormat={false}
        placeholder={`${label}`}
        // disableCountryCode={true}
        onChange={(phone: any, country: any) =>
          handlePhoneChange(phone, country)
        }
        disableInitialCountryGuess={true}
        disableCountryGuess={false}
        countryCodeEditable={true}
        disableSearchIcon={false}
        inputProps={{
          name: { name },
          required: isFieldRequired(),
        }}
        inputStyle={{
          ...styles.customStyles(
            theme,
            isFieldRequired() ? theme.palette.primary.main : undefined,
          ),
          borderColor: deepDerefrencer(formControl.errors, name) && "red",
        }}
        onBlur={formControl.handleBlur}
        defaultErrorMessage={deepDerefrencer(formControl.errors, name)}
        preferredCountries={preferredCountries}
      />
      {<FormError />}
    </FlexBox>
  );
};

const styles = {
  customStyles: (theme: any, outlineColor?: string) => ({
    width: "100%",
    height: 53,
    "& .react-tel-input": {
      borderRadius: "8px",
      border: "1px solid",
      borderColor: outlineColor
        ? outlineColor
        : theme.palette.mode === "light"
        ? theme.palette.secondary[300]
        : theme.palette.divider,
    },

    "& input[type='file'].MuiInputBase-input": {
      marginLeft: "120px",
    },
  }),
};

export default FormPhoneInput;
