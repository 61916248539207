import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { formatDateToDDMMYYYY } from "../../../utils/date_formatter";
import { figureFormatter } from "../../../utils/figure_formatter";

interface IDetailsInfo {
  entityDetails: any;
}

const SharePurchaseReversal: FC<IDetailsInfo> = ({ entityDetails }) => {
  return (
    <Card>
      <CardContent>
        <Typography
          sx={{ fontSize: 14, mb: 1.5, fontWeight: "bold" }}
          gutterBottom
        >
          Share Purchase Details
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Share Holder:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {entityDetails.shared_name
            ? entityDetails.shared_name
            : `${entityDetails?.client_account.user_details.first_name} ${entityDetails?.client_account.user_details.last_name}`}
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Number of Shares:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {entityDetails?.no_of_shares}
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Share Value:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {figureFormatter({
            figure: entityDetails?.current_share_value,
            currency: entityDetails?.currency?.currency || "",
          })}
        </Typography>

        {entityDetails?.type_display && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Transaction Type:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.type_display}
            </Typography>
          </>
        )}

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Purchase Date:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {formatDateToDDMMYYYY(entityDetails?.createdat)}
        </Typography>

        {entityDetails?.trxn_ref && (
          <>
            <Typography
              sx={{ fontSize: 14, mb: 1.5 }}
              color="text.disabled"
              gutterBottom
            >
              Reference:
            </Typography>
            <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
              {entityDetails?.trxn_ref}
            </Typography>
          </>
        )}

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Purchase Amount:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {figureFormatter({
            figure: entityDetails?.amount,
            currency: entityDetails?.currency?.currency || "",
          })}
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Payment Method:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {entityDetails?.pay_method}
        </Typography>

        <Typography
          sx={{ fontSize: 14, mb: 1.5 }}
          color="text.disabled"
          gutterBottom
        >
          Description:
        </Typography>
        <Typography sx={{ fontSize: 14, mb: 1.5 }} gutterBottom>
          {entityDetails?.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SharePurchaseReversal;
