import React, { FC } from "react";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import { Box, FormControl, InputLabel, Select } from "@mui/material";
import { deepDerefrencer } from "../../../utils/form_factory";
import uniqueId from "../../../utils/generateId";
import { Small } from "../../Typography";
import { ukoTheme } from "../../../theme";

import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { SelectChangeEvent } from "@mui/material/Select";

interface IFormMultiSelectCheckProps {
  name: string;
  label: any;
  type?: string;
  options?: any[];
  selector?: {
    value: (option: any) => any;
    label: (option: any, isSelected?: boolean) => any;
  };
  formControl: any;
  isDisabled?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FormMultiSelectCheck: FC<IFormMultiSelectCheckProps> = (
  props: IFormMultiSelectCheckProps,
) => {
  const theme = ukoTheme();
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);

  const isFieldRequired = (fieldName: string) => {
    return String(props.formControl.getFieldMeta(props.name)?.error).match(
      /required/gi,
    )
      ? true
      : false;
  };

  const getLabel = (value: any) => {
    const found = props.options?.find(
      (option) => String(props.selector?.value(option)) === String(value),
    );
    if (found) {
      return props.selector?.label(found);
    }
    return value;
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedOptions>) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(typeof value === "string" ? value.split(",") : value);
  };

  // const handleDelete = (index: number, value: any) => {
  //   const options = [...selectedOptions];
  //   const optionIndex = options.indexOf(value);
  //   if (index >= 0) {
  //     options.splice(index, 1);
  //   }
  //   setSelectedOptions(options);
  //   // setSelectedOptions(selectedOptions.filter((item: any) => item !== value));
  // };

  const isSelectedItem = (option: any) => {
    const values =
      (deepDerefrencer(props.formControl.values, props.name) as Array<any>) ||
      [];
    return values?.includes(props.selector?.value(option));
  };

  const handleDelete = (value: any) => {
    const currentValues =
      (deepDerefrencer(props.formControl.values, props.name) as Array<any>) ||
      [];

    const newValues = currentValues.filter(
      (t) => JSON.stringify(t) !== JSON.stringify(value),
    );
    props.formControl.setValues(props.name, []);
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="demo-multiple-chip-label">{props.label}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        multiple
        sx={styles.customStyles(
          theme,
          isFieldRequired(props.name) ? theme.palette.primary.main : undefined,
        )}
        id={uniqueId()}
        notched={undefined}
        label={props.label}
        variant="outlined"
        name={props.name}
        onChange={(event: any) => {
          props.formControl.handleChange(event);
          handleChange(event);
        }}
        value={deepDerefrencer(props.formControl.values, props.name)}
        error={Boolean(
          deepDerefrencer(props.formControl.touched, props.name) &&
            deepDerefrencer(props.formControl.errors, props.name),
        )}
        onBlur={props.formControl.handleBlur}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value: any, index: number) => (
              <Chip
                key={value}
                label={getLabel(value)}
                // onDelete={() => handleDelete(value)}
                onMouseDown={(event: any) => {
                  event.stopPropagation();
                }}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        disabled={props.isDisabled}
      >
        {props.options?.map((option, index) => (
          <MenuItem key={`${index}`} value={props.selector?.value(option)}>
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<CheckCircleIcon />}
              checked={
                selectedOptions.includes(props.selector?.value(option)) ||
                isSelectedItem(option)
              }
            />
            {props.selector?.label(option, isSelectedItem(option))}
          </MenuItem>
        ))}
      </Select>
      {Boolean(
        deepDerefrencer(props.formControl.touched, props.name) &&
          deepDerefrencer(props.formControl.errors, props.name),
      ) && (
        <Small
          color="error.main"
          fontSize="0.75rem"
          fontWeight={"normal"}
          marginTop={1}
        >
          {deepDerefrencer(props.formControl.errors, props.name)}
        </Small>
      )}
    </FormControl>
  );
};

const styles = {
  customStyles: (theme: any, outlineColor?: string) => ({
    "& .MuiOutlinedInput-input": {
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "2px solid",
      borderColor: outlineColor
        ? outlineColor
        : theme.palette.mode === "light"
        ? theme.palette.secondary[300]
        : theme.palette.divider,
    },
  }),
  labelColor: {
    "& .css-cit3ow-MuiFormLabel-root-MuiInputLabel-root": {
      color: "#94a5c4",
    },
  },
};

export default FormMultiSelectCheck;
