import React, { FC } from "react";
import { H6, Span } from "../../../Typography";
import StatusBtn from "../../StatusBtn";
import { Box, Button, Chip, Grid } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VerifiedIcon from "@mui/icons-material/Verified";
import CancelIcon from "@mui/icons-material/Cancel";
import FlexBox from "../../../FlexBox";
import { figureFormatter } from "../../../../utils/figure_formatter";

interface ResultsValidateIDProps {
  data: any;
  handleTryAgain?: () => void;
}

const ResultsValidateID: FC<ResultsValidateIDProps> = ({
  data,
  handleTryAgain,
}) => {
  return (
    <Box>
      <Box sx={styles.container} marginBottom={2}>
        <FlexBox gap={2} alignItems={"center"}>
          <Box sx={styles.iconCircle}>
            <AccountCircleIcon
              color="primary"
              sx={{ height: "3.8rem", width: "3.8rem" }}
            />
          </Box>
          <FlexBox flexDirection={"column"} gap={0.8}>
            <H6 marginBottom={0}>
              <Span>NIN: </Span>
              <Span sx={styles.labelValueSm}>{data?.parameter?.nin}</Span>
            </H6>
            <H6 marginBottom={0}>
              <Span>Card Number: </Span>
              <Span sx={styles.labelValueSm}>
                {data?.parameter?.document_id}
              </Span>
            </H6>
            <H6 marginBottom={0}>
              <Span>Surname: </Span>
              <Span sx={styles.labelValueSm}>{data?.result?.surname}</Span>
            </H6>
          </FlexBox>
        </FlexBox>
        <FlexBox
          flexDirection={"column"}
          gap={0.8}
          sx={{ textAlign: "center" }}
        >
          <H6 marginBottom={0}>Matching Status:</H6>

          {data?.status === 5 ? (
            <StatusBtn type={0} title={"FAILED"} />
          ) : (
            <>
              {data?.result?.status === "SUCCESSFUL" && (
                <Chip icon={<VerifiedIcon />} label="MATCHED" color="success" />
              )}

              {data?.result?.status === "FAILED" && (
                <Chip icon={<CancelIcon />} label="NOT MATCHED" color="error" />
              )}
            </>
          )}
        </FlexBox>
      </Box>

      <Box sx={styles.container} marginBottom={2}>
        <Grid container rowSpacing={2.5}>
          <Grid item xs={6}>
            <H6 marginBottom={0} sx={styles.labelText}>
              Nin Status
            </H6>
            <Span sx={styles.labelValue}>
              {data?.result?.nin_status ? (
                <StatusBtn type={1} title={data?.result?.nin_status} />
              ) : (
                "---"
              )}
            </Span>
          </Grid>
          <Grid item xs={6}>
            <H6 marginBottom={0} sx={styles.labelText}>
              Card Status
            </H6>
            <Span sx={styles.labelValue}>
              {data?.result?.card_status ? (
                <StatusBtn
                  type={data?.result?.card_status === "INVALID" ? 0 : 1}
                  title={data?.result?.card_status}
                />
              ) : (
                "---"
              )}
            </Span>
          </Grid>
          <Grid item xs={6}>
            <H6 marginBottom={0} sx={styles.labelText}>
              Cost of Check
            </H6>
            <Span sx={styles.labelValue}>
              {data?.service_cost
                ? figureFormatter({
                    figure: data?.service_cost,
                    currency: data?.currency?.currency,
                  })
                : "---"}
            </Span>
          </Grid>
        </Grid>
      </Box>

      {[5].includes(data?.status) && (
        <FlexBox alignItems={"center"} justifyContent={"flex-start"} gap={2}>
          <Button
            size="small"
            color="inherit"
            variant="contained"
            sx={{ backgroundColor: "#FFFFFF", border: "1px solid #D9D9D9" }}
            onClick={handleTryAgain ? handleTryAgain : () => {}}
          >
            Try Again
          </Button>
        </FlexBox>
      )}
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem",
    backgroundColor: "#EFEFEF",
    borderRadius: "0.5rem",
  },
  labelText: {
    fontSize: "0.8rem",
    fontWeight: 400,
    color: "#000000",
  },
  labelValue: {
    fontSize: "1rem",
    fontWeight: 500,
    color: "#5F6368",
  },
  labelValueSm: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "#5F6368",
  },
  iconCircle: {
    height: "4rem",
    width: "4rem",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default ResultsValidateID;
