export const CURRENCY_CODES = [
  {
    country: "Afghanistan",
    currency_code: "AFN",
    country_code: "AF",
    phone_code: "93",
  },
  {
    country: "Albania",
    currency_code: "ALL",
    country_code: "AL",
    phone_code: "355",
  },
  {
    country: "Algeria",
    currency_code: "DZD",
    country_code: "DZ",
    phone_code: "213",
  },
  {
    country: "American Samoa",
    currency_code: "USD",
    country_code: "AS",
    phone_code: "1684",
  },
  {
    country: "Andorra",
    currency_code: "EUR",
    country_code: "AD",
    phone_code: "376",
  },
  {
    country: "Angola",
    currency_code: "AOA",
    country_code: "AO",
    phone_code: "244",
  },
  {
    country: "Anguilla",
    currency_code: "XCD",
    country_code: "AI",
    phone_code: "1264",
  },
  {
    country: "Antarctica",
    currency_code: "XCD",
    country_code: "AQ",
    phone_code: "672",
  },
  {
    country: "Antigua and Barbuda",
    currency_code: "XCD",
    country_code: "AG",
    phone_code: "1268",
  },
  {
    country: "Argentina",
    currency_code: "ARS",
    country_code: "AR",
    phone_code: "54",
  },
  {
    country: "Armenia",
    currency_code: "AMD",
    country_code: "AM",
    phone_code: "374",
  },
  {
    country: "Aruba",
    currency_code: "AWG",
    country_code: "AW",
    phone_code: "297",
  },
  {
    country: "Australia",
    currency_code: "AUD",
    country_code: "AU",
    phone_code: "61",
  },
  {
    country: "Austria",
    currency_code: "EUR",
    country_code: "AT",
    phone_code: "43",
  },
  {
    country: "Azerbaijan",
    currency_code: "AZN",
    country_code: "AZ",
    phone_code: "994",
  },
  {
    country: "Bahamas",
    currency_code: "BSD",
    country_code: "BS",
    phone_code: "1242",
  },
  {
    country: "Bahrain",
    currency_code: "BHD",
    country_code: "BH",
    phone_code: "973",
  },
  {
    country: "Bangladesh",
    currency_code: "BDT",
    country_code: "BD",
    phone_code: "880",
  },
  {
    country: "Barbados",
    currency_code: "BBD",
    country_code: "BB",
    phone_code: "1246",
  },
  {
    country: "Belarus",
    currency_code: "BYR",
    country_code: "BY",
    phone_code: "375",
  },
  {
    country: "Belgium",
    currency_code: "EUR",
    country_code: "BE",
    phone_code: "32",
  },
  {
    country: "Belize",
    currency_code: "BZD",
    country_code: "BZ",
    phone_code: "501",
  },
  {
    country: "Benin",
    currency_code: "XOF",
    country_code: "BJ",
    phone_code: "229",
  },
  {
    country: "Bermuda",
    currency_code: "BMD",
    country_code: "BM",
    phone_code: "1441",
  },
  {
    country: "Bhutan",
    currency_code: "BTN",
    country_code: "BT",
    phone_code: "975",
  },
  {
    country: "Bolivia",
    currency_code: "BOB",
    country_code: "BO",
    phone_code: "591",
  },
  {
    country: "Bosnia and Herzegovina",
    currency_code: "BAM",
    country_code: "BA",
    phone_code: "387",
  },
  {
    country: "Botswana",
    currency_code: "BWP",
    country_code: "BW",
    phone_code: "267",
  },
  {
    country: "Bouvet Island",
    currency_code: "NOK",
    country_code: "BV",
    phone_code: "47",
  },
  {
    country: "Brazil",
    currency_code: "BRL",
    country_code: "BR",
    phone_code: "55",
  },
  {
    country: "United Kingdom of Great Britain and Northern Ireland (the)",
    currency_code: "GBR",
    country_code: "GB",
    phone_code: "44",
  },
  {
    country: "British Indian Ocean Territory",
    currency_code: "USD",
    country_code: "IO",
    phone_code: "246",
  },
  {
    country: "Brunei",
    currency_code: "BND",
    country_code: "BN",
    phone_code: "673",
  },
  {
    country: "Bulgaria",
    currency_code: "BGN",
    country_code: "BG",
    phone_code: "359",
  },
  {
    country: "Burkina Faso",
    currency_code: "XOF",
    country_code: "BF",
    phone_code: "226",
  },
  {
    country: "Burundi",
    currency_code: "BIF",
    country_code: "BI",
    phone_code: "257",
  },
  {
    country: "Cambodia",
    currency_code: "KHR",
    country_code: "KH",
    phone_code: "855",
  },
  {
    country: "Cameroon",
    currency_code: "XAF",
    country_code: "CM",
    phone_code: "237",
  },
  {
    country: "Canada",
    currency_code: "CAD",
    country_code: "CA",
    phone_code: "1",
  },
  {
    country: "Cape Verde",
    currency_code: "CVE",
    country_code: "CV",
    phone_code: "238",
  },
  {
    country: "Cayman Islands",
    currency_code: "KYD",
    country_code: "KY",
    phone_code: "1345",
  },
  {
    country: "Central African Republic",
    currency_code: "XAF",
    country_code: "CF",
    phone_code: "236",
  },
  {
    country: "Chad",
    currency_code: "XAF",
    country_code: "TD",
    phone_code: "235",
  },
  {
    country: "Chile",
    currency_code: "CLP",
    country_code: "CL",
    phone_code: "56",
  },
  {
    country: "China",
    currency_code: "CNY",
    country_code: "CN",
    phone_code: "86",
  },
  {
    country: "Christmas Island",
    currency_code: "AUD",
    country_code: "CX",
    phone_code: "61",
  },
  {
    country: "Cocos (Keeling) Islands",
    currency_code: "AUD",
    country_code: "CC",
    phone_code: "61",
  },
  {
    country: "Colombia",
    currency_code: "COP",
    country_code: "C0",
    phone_code: "57",
  },
  {
    country: "Comoros",
    currency_code: "KMF",
    country_code: "KM",
    phone_code: "269",
  },
  {
    country: "Congo",
    currency_code: "XAF",
    country_code: "CD",
    phone_code: "242",
  },
  {
    country: "Cook Islands",
    currency_code: "NZD",
    country_code: "CK",
    phone_code: "682",
  },
  {
    country: "Costa Rica",
    currency_code: "CRC",
    country_code: "CR",
    phone_code: "506",
  },
  {
    country: "Croatia",
    currency_code: "HRK",
    country_code: "HR",
    phone_code: "385",
  },
  {
    country: "Cuba",
    currency_code: "CUP",
    country_code: "CU",
    phone_code: "53",
  },
  {
    country: "Cyprus",
    currency_code: "EUR",
    country_code: "CY",
    phone_code: "357",
  },
  {
    country: "Czech Republic",
    currency_code: "CZK",
    country_code: "CZ",
    phone_code: "420",
  },
  {
    country: "Denmark",
    currency_code: "DKK",
    country_code: "DK",
    phone_code: "45",
  },
  {
    country: "Djibouti",
    currency_code: "DJF",
    country_code: "DJ",
    phone_code: "253",
  },
  {
    country: "Dominica",
    currency_code: "XCD",
    country_code: "DM",
    phone_code: "1767",
  },
  {
    country: "Dominican Republic",
    currency_code: "DOP",
    country_code: "DO",
    phone_code: "1809",
  },
  {
    country: "East Timor",
    currency_code: "USD",
    country_code: "US",
    phone_code: "670",
  },
  {
    country: "Ecuador",
    currency_code: "ECS",
    country_code: "EC",
    phone_code: "593",
  },
  {
    country: "Egypt",
    currency_code: "EGP",
    country_code: "EG",
    phone_code: "20",
  },
  {
    country: "El Salvador",
    currency_code: "SVC",
    country_code: "SV",
    phone_code: "503",
  },
  {
    country: "Equatorial Guinea",
    currency_code: "XAF",
    country_code: "GQ",
    phone_code: "240",
  },
  {
    country: "Eritrea",
    currency_code: "ERN",
    country_code: "ER",
    phone_code: "291",
  },
  {
    country: "Estonia",
    currency_code: "EUR",
    country_code: "EE",
    phone_code: "372",
  },
  {
    country: "Ethiopia",
    currency_code: "ETB",
    country_code: "ET",
    phone_code: "251",
  },
  {
    country: "Falkland Islands",
    currency_code: "FKP",
    country_code: "FK",
    phone_code: "500",
  },
  {
    country: "Faroe Islands",
    currency_code: "DKK",
    country_code: "FO",
    phone_code: "298",
  },
  {
    country: "Fiji Islands",
    currency_code: "FJD",
    country_code: "FJ",
    phone_code: "679",
  },
  {
    country: "Finland",
    currency_code: "EUR",
    country_code: "FI",
    phone_code: "358",
  },
  {
    country: "France",
    currency_code: "EUR",
    country_code: "FR",
    phone_code: "33",
  },
  {
    country: "French Guiana",
    currency_code: "EUR",
    country_code: "GF",
    phone_code: "594",
  },
  {
    country: "French Polynesia",
    currency_code: "XPF",
    country_code: "PF",
    phone_code: "689",
  },
  {
    country: "French Southern territories",
    currency_code: "EUR",
    country_code: "TF",
    phone_code: "262",
  },
  {
    country: "Gabon",
    currency_code: "XAF",
    country_code: "GA",
    phone_code: "241",
  },
  {
    country: "Gambia",
    currency_code: "GMD",
    country_code: "GM",
    phone_code: "220",
  },
  {
    country: "Georgia",
    currency_code: "GEL",
    country_code: "GE",
    phone_code: "995",
  },
  {
    country: "Germany",
    currency_code: "EUR",
    country_code: "DE",
    phone_code: "49",
  },
  {
    country: "Ghana",
    currency_code: "GHS",
    country_code: "GH",
    phone_code: "233",
  },
  {
    country: "Gibraltar",
    currency_code: "GIP",
    country_code: "GI",
    phone_code: "350",
  },
  {
    country: "Greece",
    currency_code: "EUR",
    country_code: "GR",
    phone_code: "30",
  },
  {
    country: "Greenland",
    currency_code: "DKK",
    country_code: "GL",
    phone_code: "299",
  },
  {
    country: "Grenada",
    currency_code: "XCD",
    country_code: "GD",
    phone_code: "1473",
  },
  {
    country: "Guadeloupe",
    currency_code: "EUR",
    country_code: "GP",
    phone_code: "590",
  },
  {
    country: "Guam",
    currency_code: "USD",
    country_code: "GU",
    phone_code: "1671",
  },
  {
    country: "Guatemala",
    currency_code: "QTQ",
    country_code: "GT",
    phone_code: "502",
  },
  {
    country: "Guinea",
    currency_code: "GNF",
    country_code: "GN",
    phone_code: "224",
  },
  {
    country: "Guinea-Bissau",
    currency_code: "CFA",
    country_code: "GW",
    phone_code: "245",
  },
  {
    country: "Guyana",
    currency_code: "GYD",
    country_code: "GY",
    phone_code: "592",
  },
  {
    country: "Haiti",
    currency_code: "HTG",
    country_code: "HT",
    phone_code: "509",
  },
  {
    country: "Heard Island and McDonald Islands",
    currency_code: "AUD",
    country_code: "HM",
    phone_code: "672",
  },
  {
    country: "Holy See (Vatican City State)",
    currency_code: "EUR",
    country_code: "VA",
    phone_code: "379",
  },
  {
    country: "Honduras",
    currency_code: "HNL",
    country_code: "HN",
    phone_code: "504",
  },
  {
    country: "Hong Kong",
    currency_code: "HKD",
    country_code: "HK",
    phone_code: "852",
  },
  {
    country: "Hungary",
    currency_code: "HUF",
    country_code: "HU",
    phone_code: "36",
  },
  {
    country: "Iceland",
    currency_code: "ISK",
    country_code: "IS",
    phone_code: "354",
  },
  {
    country: "India",
    currency_code: "INR",
    country_code: "IN",
    phone_code: "91",
  },
  {
    country: "Indonesia",
    currency_code: "IDR",
    country_code: "ID",
    phone_code: "62",
  },
  {
    country: "Iran",
    currency_code: "IRR",
    country_code: "IR",
    phone_code: "98",
  },
  {
    country: "Iraq",
    currency_code: "IQD",
    country_code: "IQ",
    phone_code: "964",
  },
  {
    country: "Ireland",
    currency_code: "EUR",
    country_code: "IE",
    phone_code: "353",
  },
  {
    country: "Israel",
    currency_code: "ILS",
    country_code: "IL",
    phone_code: "972",
  },
  {
    country: "Italy",
    currency_code: "EUR",
    country_code: "IT",
    phone_code: "39",
  },
  {
    country: "Ivory Coast",
    currency_code: "XOF",
    country_code: "CI",
    phone_code: "225",
  },
  {
    country: "Jamaica",
    currency_code: "JMD",
    country_code: "JM",
    phone_code: "1876",
  },
  {
    country: "Japan",
    currency_code: "JPY",
    country_code: "JP",
    phone_code: "81",
  },
  {
    country: "Jordan",
    currency_code: "JOD",
    country_code: "JO",
    phone_code: "962",
  },
  {
    country: "Kazakhstan",
    currency_code: "KZT",
    country_code: "KZ",
    phone_code: "7",
  },
  {
    country: "Kenya",
    currency_code: "KES",
    country_code: "KE",
    phone_code: "254",
  },
  {
    country: "Kiribati",
    currency_code: "AUD",
    country_code: "KI",
    phone_code: "686",
  },
  {
    country: "Kuwait",
    currency_code: "KWD",
    country_code: "KW",
    phone_code: "965",
  },
  {
    country: "Kyrgyzstan",
    currency_code: "KGS",
    country_code: "KG",
    phone_code: "996",
  },
  {
    country: "Laos",
    currency_code: "LAK",
    country_code: "LA",
    phone_code: "856",
  },
  {
    country: "Latvia",
    currency_code: "LVL",
    country_code: "LV",
    phone_code: "371",
  },
  {
    country: "Lebanon",
    currency_code: "LBP",
    country_code: "LB",
    phone_code: "961",
  },
  {
    country: "Lesotho",
    currency_code: "LSL",
    country_code: "LS",
    phone_code: "266",
  },
  {
    country: "Liberia",
    currency_code: "LRD",
    country_code: "LR",
    phone_code: "231",
  },
  {
    country: "Libyan Arab Jamahiriya",
    currency_code: "LYD",
    country_code: "LY",
    phone_code: "218",
  },
  {
    country: "Liechtenstein",
    currency_code: "CHF",
    country_code: "LI",
    phone_code: "423",
  },
  {
    country: "Lithuania",
    currency_code: "LTL",
    country_code: "LT",
    phone_code: "370",
  },
  {
    country: "Luxembourg",
    currency_code: "EUR",
    country_code: "LU",
    phone_code: "352",
  },
  {
    country: "Macau",
    currency_code: "MOP",
    country_code: "MO",
    phone_code: "853",
  },
  {
    country: "North Macedonia",
    currency_code: "MKD",
    country_code: "MK",
    phone_code: "389",
  },
  {
    country: "Madagascar",
    currency_code: "MGF",
    country_code: "MG",
    phone_code: "261",
  },
  {
    country: "Malawi",
    currency_code: "MWK",
    country_code: "MW",
    phone_code: "265",
  },
  {
    country: "Malaysia",
    currency_code: "MYR",
    country_code: "MY",
    phone_code: "60",
  },
  {
    country: "Maldives",
    currency_code: "MVR",
    country_code: "MV",
    phone_code: "960",
  },
  {
    country: "Mali",
    currency_code: "XOF",
    country_code: "ML",
    phone_code: "223",
  },
  {
    country: "Malta",
    currency_code: "EUR",
    country_code: "MT",
    phone_code: "356",
  },
  {
    country: "Marshall Islands",
    currency_code: "USD",
    country_code: "MH",
    phone_code: "692",
  },
  {
    country: "Martinique",
    currency_code: "EUR",
    country_code: "MQ",
    phone_code: "596",
  },
  {
    country: "Mauritania",
    currency_code: "MRO",
    country_code: "MR",
    phone_code: "222",
  },
  {
    country: "Mauritius",
    currency_code: "MUR",
    country_code: "MU",
    phone_code: "230",
  },
  {
    country: "Mayotte",
    currency_code: "EUR",
    country_code: "YT",
    phone_code: "262",
  },
  {
    country: "Mexico",
    currency_code: "MXN",
    country_code: "MX",
    phone_code: "52",
  },
  {
    country: "Micronesia, Federated States of",
    currency_code: "USD",
    country_code: "FM",
    phone_code: "691",
  },
  {
    country: "Moldova",
    currency_code: "MDL",
    country_code: "MD",
    phone_code: "373",
  },
  {
    country: "Monaco",
    currency_code: "EUR",
    country_code: "MC",
    phone_code: "377",
  },
  {
    country: "Mongolia",
    currency_code: "MNT",
    country_code: "MN",
    phone_code: "976",
  },
  {
    country: "Montenegro",
    currency_code: "EUR",
    country_code: "ME",
    phone_code: "382",
  },
  {
    country: "Montserrat",
    currency_code: "XCD",
    country_code: "MS",
    phone_code: "1664",
  },
  {
    country: "Morocco",
    currency_code: "MAD",
    country_code: "MA",
    phone_code: "212",
  },
  {
    country: "Mozambique",
    currency_code: "MZN",
    country_code: "MZ",
    phone_code: "258",
  },
  {
    country: "Myanmar",
    currency_code: "MMR",
    country_code: "MM",
    phone_code: "95",
  },
  {
    country: "Namibia",
    currency_code: "NAD",
    country_code: "NA",
    phone_code: "264",
  },
  {
    country: "Nauru",
    currency_code: "AUD",
    country_code: "NR",
    phone_code: "683",
  },
  {
    country: "Nepal",
    currency_code: "NPR",
    country_code: "NP",
    phone_code: "977",
  },
  {
    country: "Netherlands",
    currency_code: "EUR",
    country_code: "NL",
    phone_code: "31",
  },
  {
    country: "Netherlands Antilles",
    currency_code: "ANG",
    country_code: "NL",
    phone_code: "599",
  },
  {
    country: "New Caledonia",
    currency_code: "XPF",
    country_code: "NC",
    phone_code: "687",
  },
  {
    country: "New Zealand",
    currency_code: "NZD",
    country_code: "NZ",
    phone_code: "64",
  },
  {
    country: "Nicaragua",
    currency_code: "NIO",
    country_code: "NI",
    phone_code: "505",
  },
  {
    country: "Niger",
    currency_code: "XOF",
    country_code: "NE",
    phone_code: "227",
  },
  {
    country: "Nigeria",
    currency_code: "NGN",
    country_code: "NG",
    phone_code: "234",
  },
  {
    country: "Niue",
    currency_code: "NZD",
    country_code: "NU",
    phone_code: "683",
  },
  {
    country: "Norfolk Island",
    currency_code: "AUD",
    country_code: "NF",
    phone_code: "672",
  },
  {
    country: "North Korea",
    currency_code: "KPW",
    country_code: "KP",
    phone_code: "850",
  },
  {
    country: "Northern Ireland",
    currency_code: "GBP",
    country_code: "GB",
    phone_code: "44",
  },
  {
    country: "Northern Mariana Islands",
    currency_code: "USD",
    country_code: "MP",
    phone_code: "1670",
  },
  {
    country: "Norway",
    currency_code: "NOK",
    country_code: "NO",
    phone_code: "47",
  },
  {
    country: "Oman",
    currency_code: "OMR",
    country_code: "OM",
    phone_code: "968",
  },
  {
    country: "Pakistan",
    currency_code: "PKR",
    country_code: "PK",
    phone_code: "92",
  },
  {
    country: "Palau",
    currency_code: "USD",
    country_code: "PW",
    phone_code: "680",
  },
  {
    country: "Palestine",
    currency_code: null,
    country_code: "PS",
    phone_code: "970",
  },
  {
    country: "Panama",
    currency_code: "PAB",
    country_code: "PA",
    phone_code: "507",
  },
  {
    country: "Papua New Guinea",
    currency_code: "PGK",
    country_code: "PG",
    phone_code: "675",
  },
  {
    country: "Paraguay",
    currency_code: "PYG",
    country_code: "PY",
    phone_code: "595",
  },
  {
    country: "Peru",
    currency_code: "PEN",
    country_code: "PE",
    phone_code: "51",
  },
  {
    country: "Philippines",
    currency_code: "PHP",
    country_code: "PH",
    phone_code: "63",
  },
  {
    country: "Pitcairn Islands",
    currency_code: "NZD",
    country_code: "PN",
    phone_code: "64",
  },
  {
    country: "Poland",
    currency_code: "PLN",
    country_code: "PL",
    phone_code: "48",
  },
  {
    country: "Portugal",
    currency_code: "EUR",
    country_code: "PT",
    phone_code: "351",
  },
  {
    country: "Puerto Rico",
    currency_code: "USD",
    country_code: "PR",
    phone_code: "1787",
  },
  {
    country: "Qatar",
    currency_code: "QAR",
    country_code: "QA",
    phone_code: "974",
  },
  {
    country: "Reunion",
    currency_code: "EUR",
    country_code: "RE",
    phone_code: "262",
  },
  {
    country: "Romania",
    currency_code: "RON",
    country_code: "RO",
    phone_code: "40",
  },
  {
    country: "Russian Federation",
    currency_code: "RUB",
    country_code: "RU",
    phone_code: "7",
  },
  {
    country: "Rwanda",
    currency_code: "RWF",
    country_code: "RW",
    phone_code: "250",
  },
  {
    country: "Saint Helena",
    currency_code: "SHP",
    country_code: "SH",
    phone_code: "290",
  },
  {
    country: "Saint Kitts and Nevis",
    currency_code: "XCD",
    country_code: "KN",
    phone_code: "1869",
  },
  {
    country: "Saint Lucia",
    currency_code: "XCD",
    country_code: "LC",
    phone_code: "1758",
  },
  {
    country: "Saint Pierre and Miquelon",
    currency_code: "EUR",
    country_code: "PM",
    phone_code: "508",
  },
  {
    country: "Saint Vincent and the Grenadines",
    currency_code: "XCD",
    country_code: "VC",
    phone_code: "1784",
  },
  {
    country: "Samoa",
    currency_code: "WST",
    country_code: "WS",
    phone_code: "685",
  },
  {
    country: "San Marino",
    currency_code: "EUR",
    country_code: "SM",
    phone_code: "378",
  },
  {
    country: "Sao Tome and Principe",
    currency_code: "STD",
    country_code: "ST",
    phone_code: "239",
  },
  {
    country: "Saudi Arabia",
    currency_code: "SAR",
    country_code: "SA",
    phone_code: "966",
  },
  {
    country: "Scotland",
    currency_code: "GBP",
    country_code: "GB",
    phone_code: "44",
  },
  {
    country: "Senegal",
    currency_code: "XOF",
    country_code: "SN",
    phone_code: "221",
  },
  {
    country: "Serbia",
    currency_code: "RSD",
    country_code: "RS",
    phone_code: "381",
  },
  {
    country: "Seychelles",
    currency_code: "SCR",
    country_code: "SC",
    phone_code: "248",
  },
  {
    country: "Sierra Leone",
    currency_code: "SLL",
    country_code: "SL",
    phone_code: "232",
  },
  {
    country: "Singapore",
    currency_code: "SGD",
    country_code: "SG",
    phone_code: "65",
  },
  {
    country: "Slovakia",
    currency_code: "EUR",
    country_code: "SK",
    phone_code: "421",
  },
  {
    country: "Slovenia",
    currency_code: "EUR",
    country_code: "SI",
    phone_code: "386",
  },
  {
    country: "Solomon Islands",
    currency_code: "SBD",
    country_code: "SB",
    phone_code: "677",
  },
  {
    country: "Somalia",
    currency_code: "SOS",
    country_code: "SO",
    phone_code: "252",
  },
  {
    country: "South Africa",
    currency_code: "ZAR",
    country_code: "ZA",
    phone_code: "27",
  },
  {
    country: "South Georgia and the South Sandwich Islands",
    currency_code: "GBP",
    country_code: "GS",
    phone_code: "500",
  },
  {
    country: "South Korea",
    currency_code: "KRW",
    country_code: "KR",
    phone_code: "82",
  },
  {
    country: "South Sudan",
    currency_code: "SSP",
    country_code: "SS",
    phone_code: "211",
  },
  {
    country: "Spain",
    currency_code: "EUR",
    country_code: "ES",
    phone_code: "34",
  },
  {
    country: "Sri Lanka",
    currency_code: "LKR",
    country_code: "LK",
    phone_code: "94",
  },
  {
    country: "Sudan",
    currency_code: "SDG",
    country_code: "SD",
    phone_code: "249",
  },
  {
    country: "Suriname",
    currency_code: "SRD",
    country_code: "SR",
    phone_code: "597",
  },
  {
    country: "Svalbard and Jan Mayen",
    currency_code: "NOK",
    country_code: "SJ",
    phone_code: "47",
  },
  {
    country: "Swaziland",
    currency_code: "SZL",
    country_code: "SZ",
    phone_code: "268",
  },
  {
    country: "Sweden",
    currency_code: "SEK",
    country_code: "SE",
    phone_code: "46",
  },
  {
    country: "Switzerland",
    currency_code: "CHF",
    country_code: "CH",
    phone_code: "41",
  },
  {
    country: "Syria",
    currency_code: "SYP",
    country_code: "SY",
    phone_code: "963",
  },
  {
    country: "Tajikistan",
    currency_code: "TJS",
    country_code: "TJ",
    phone_code: "992",
  },
  {
    country: "Tanzania",
    currency_code: "TZS",
    country_code: "TZ",
    phone_code: "255",
  },
  {
    country: "Thailand",
    currency_code: "THB",
    country_code: "TH",
    phone_code: "66",
  },
  {
    country: "The Democratic Republic of Congo",
    currency_code: "CDF",
    country_code: "CD",
    phone_code: "243",
  },
  {
    country: "Togo",
    currency_code: "XOF",
    country_code: "TG",
    phone_code: "228",
  },
  {
    country: "Tokelau",
    currency_code: "NZD",
    country_code: "TK",
    phone_code: "690",
  },
  {
    country: "Tonga",
    currency_code: "TOP",
    country_code: "TO",
    phone_code: "676",
  },
  {
    country: "Trinidad and Tobago",
    currency_code: "TTD",
    country_code: "TT",
    phone_code: "1868",
  },
  {
    country: "Tunisia",
    currency_code: "TND",
    country_code: "TN",
    phone_code: "216",
  },
  {
    country: "Turkey",
    currency_code: "TRY",
    country_code: "TR",
    phone_code: "90",
  },
  {
    country: "Turkmenistan",
    currency_code: "TMT",
    country_code: "TM",
    phone_code: "993",
  },
  {
    country: "Turks and Caicos Islands",
    currency_code: "USD",
    country_code: "TC",
    phone_code: "1649",
  },
  {
    country: "Tuvalu",
    currency_code: "AUD",
    country_code: "TV",
    phone_code: "688",
  },
  {
    country: "Uganda",
    currency_code: "UGX",
    country_code: "UG",
    phone_code: "256",
  },
  {
    country: "Ukraine",
    currency_code: "UAH",
    country_code: "UA",
    phone_code: "380",
  },
  {
    country: "United Arab Emirates",
    currency_code: "AED",
    country_code: "AE",
    phone_code: "971",
  },
  {
    country: "United Kingdom",
    currency_code: "GBP",
    country_code: "GB",
    phone_code: "44",
  },
  {
    country: "United States",
    currency_code: "USD",
    country_code: "US",
    phone_code: "1",
  },
  {
    country: "United States Minor Outlying Islands",
    currency_code: "USD",
    country_code: "UM",
    phone_code: "1",
  },
  {
    country: "Uruguay",
    currency_code: "UYU",
    country_code: "UY",
    phone_code: "598",
  },
  {
    country: "Uzbekistan",
    currency_code: "UZS",
    country_code: "UZ",
    phone_code: "998",
  },
  {
    country: "Vanuatu",
    currency_code: "VUV",
    country_code: "VU",
    phone_code: "678",
  },
  {
    country: "Venezuela",
    currency_code: "VEF",
    country_code: "VE",
    phone_code: "58",
  },
  {
    country: "Vietnam",
    currency_code: "VND",
    country_code: "VN",
    phone_code: "84",
  },
  {
    country: "Virgin Islands, British",
    currency_code: "USD",
    country_code: "VG",
    phone_code: "1284",
  },
  {
    country: "Virgin Islands, U.S.",
    currency_code: "USD",
    country_code: "VI",
    phone_code: "1340",
  },
  {
    country: "Wales",
    currency_code: "GBP",
    country_code: "GB",
    phone_code: "44",
  },
  {
    country: "Wallis and Futuna",
    currency_code: "XPF",
    country_code: "WF",
    phone_code: "681",
  },
  {
    country: "Western Sahara",
    currency_code: "MAD",
    country_code: "EH",
    phone_code: "212",
  },
  {
    country: "Yemen",
    currency_code: "YER",
    country_code: "YE",
    phone_code: "967",
  },
  {
    country: "Zambia",
    currency_code: "ZMW",
    country_code: "ZM",
    phone_code: "260",
  },
  {
    country: "Zimbabwe",
    currency_code: "ZWD",
    country_code: "ZW",
    phone_code: "263",
  },
];

export const fetchCountries = (search: string) => {
  return CURRENCY_CODES.map((code: any) => code.country).filter((country) =>
    country.toLowerCase().includes(search.toLowerCase()),
  );
};

export const fetchCurrencies = (search: string) => {
  return CURRENCY_CODES.map(
    (code: any) => `${code.currency_code} - ${code.country}`,
  ).filter((currency) => currency.toLowerCase().includes(search.toLowerCase()));
};

export const getCountryPhoneCode = (countryCode: string) => {
  return (
    CURRENCY_CODES.find((code) => code.country_code === countryCode)
      ?.phone_code || "Unknown"
  );
};
