import { IFormField } from "../../../../../utils/form_factory";

export const FormFields = (currencyOptions: any): IFormField[] => [
  {
    name: "amount",
    initailValue: "1",
    label: "Amount",
    type: "number",
    uiBreakpoints: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    uiType: "text",
  },
  {
    name: "from_currency",
    initailValue: "",
    label: "From",
    type: "text",
    uiBreakpoints: { xs: 12, sm: 12, md: 5.5, lg: 5.5, xl: 5.5 },
    uiType: "select",
    options: currencyOptions,
    selector: {
      value: (option: any) => option?.currency || option,
      label: (option: any) => option?.currency || option,
    },
  },
  {
    name: "to_currency",
    initailValue: "",
    label: "To",
    type: "text",
    uiBreakpoints: { xs: 12, sm: 12, md: 5.5, lg: 5.5, xl: 5.5 },
    uiType: "select",
    options: currencyOptions,
    selector: {
      value: (option: any) => option?.currency || option,
      label: (option: any) => option?.currency || option,
    },
  },
];
