import React, {
  createContext,
  useContext,
  FC,
  useState,
  useEffect,
} from "react";
import { useAuth } from "../hooks/useAuth";
import LocationStore from "../store/location.store";
import axios from "axios";

export interface ILocationContext {}
const initialState: ILocationContext = {};
export const UserLocationContext = createContext<ILocationContext | undefined>({
  ...initialState,
});

export const useUserLocationContext = () => {
  const context = useContext(UserLocationContext);

  if (!context) {
    throw new Error(
      "useModalContext must be used within a Location Context Provider",
    );
  }

  return context;
};

export const UserLocationProvider: FC<any> = ({ children }) => {
  const auth: any = useAuth();
  const [location, setLocation] = useState<any>([]);
  const Provider = UserLocationContext.Provider as any;

  useEffect(() => {
    const fetchLocationDetails = async () => {
      await axios
        .get("http://ip-api.com/json")
        .then((response: any) => {
          setLocation(response?.data);
        })
        .catch((error: any) => {
          LocationStore.update([]);
        });
    };
    fetchLocationDetails();
  }, [auth]);

  return <Provider value={{ location, setLocation }}>{children}</Provider>;
};
