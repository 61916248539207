export interface IFormatFigureProps {
  figure: any;
  decimalPlaces?: number;
  currency?: string;
  color?: string;
  size?: number;
  className?: string;
  extraStyles?: any;
}

export const figureFormatter = (payload: IFormatFigureProps) => {
  if (payload.figure === undefined || payload.figure === null) return "";

  const formattedFigure =
    typeof payload.figure == "string"
      ? payload.figure
      : Number(payload.figure).toFixed(payload?.decimalPlaces);

  const commaFormattedFigure = formattedFigure
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return String(
    `${payload.currency ? payload.currency : ""} ${commaFormattedFigure}`,
  ).trim();
};
