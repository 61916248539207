import { Subject } from "rxjs";
import _ from "lodash";

const subject = new Subject();
const initialState: any = {};
let state = initialState;

const CurrenciesStore = {
  init: (data: any) => subject.next({ ...state, ...data }),
  subscribe: (setState: any) => subject.subscribe(setState),
  update: (payload: any) => {
    state = {
      currencies: [...payload].sort(function (x, y) {
        return x.default === true ? -1 : y.default === true ? 1 : 0;
      }),
      baseCurrency:
        [...payload].find((currency: any) => currency.default === true) || {},
    };
    subject.next(state);
  },
  initialState,
};

export default CurrenciesStore;
