import { Badge, Box, Button, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useDrawerContext } from "../../../../contexts/DrawerContext";
import DrawerBox from "../../../UI/DrawerBox";
import toast from "react-hot-toast";
import { useAuth } from "../../../../hooks/useAuth";
import CurrencyExchangeRoundedIcon from "@mui/icons-material/CurrencyExchangeRounded";
import { ukoTheme } from "../../../../theme";
import ProgressPaper from "../../../UI/ProgressPaper";
import { H4 } from "../../../Typography";
import CloseIcon from "@mui/icons-material/Close";
import CurrencyConverterForm from "./CurrencyConverterForm";

const CurrencyConverter: FC = () => {
  const { user }: any = useAuth();
  const { showDrawer, setShowDrawer, setDrawerId } = useDrawerContext();
  const [taskCount, setTaskCount] = useState(0);
  const [showCurrencySettings, setShowCurrencySettings] =
    useState<boolean>(false);
  const theme = ukoTheme();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {}, [user.type]);

  const handleOpenDrawer = () => {
    setDrawerId("currency-converter");
    setShowDrawer({ ...showDrawer, ...{ right: true } });
  };

  const closeDrawer = () => {
    setShowDrawer({ ...showDrawer, ...{ right: false } });
  };

  return (
    <>
      <IconButton onClick={handleOpenDrawer}>
        <Badge color="error" badgeContent={taskCount}>
          <CurrencyExchangeRoundedIcon
            fontSize="medium"
            sx={{ color: "text.disabled" }}
          />
        </Badge>
      </IconButton>

      <DrawerBox
        minWidth={250}
        maxWidth={350}
        selectedDrawerId="currency-converter"
        anchor="right"
        onClose={() => {}}
      >
        <Box sx={{ height: "100%", display: "flex", overflowX: "hidden" }}>
          <Box
            sx={{
              ...styles.mainContainer,
              display: showCurrencySettings ? "none" : "block",
              "&": {
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                },
              },
            }}
          >
            <ProgressPaper
              extraStyles={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                overflow: "hidden !important",
              }}
              loading={loading}
            >
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  position: "sticky",
                  top: 0,
                  zIndex: 100,
                }}
              >
                <Box sx={{ ...styles.currency, ...styles.displayFlex }}>
                  <H4 fontWeight={"bold"}>Currency Converter</H4>
                  <Button
                    onClick={closeDrawer}
                    color="inherit"
                    variant={"contained"}
                    sx={{ ...styles.closeButton, marginLeft: "auto" }}
                  >
                    <CloseIcon />
                  </Button>
                </Box>
                <Box sx={{ ...styles.currency }}>
                  <CurrencyConverterForm />
                </Box>
              </Box>
            </ProgressPaper>
          </Box>
        </Box>
      </DrawerBox>
    </>
  );
};

const styles = {
  mainContainer: {
    height: "100%",
    width: 600,
    borderRight: "1px solid #e0e0e0",
    transition: "width 0.5s ease-in-out",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },
  currency: {
    padding: 2,
    borderBottom: "1px solid #e0e0e0",
  },
  closeButton: {
    borderRadius: "50%",
    padding: "0 !important",
    width: "2rem",
    height: "2rem",
    minWidth: "2rem",
    backgroundColor: "white",

    "&:hover": {
      backgroundColor: "inherit.main",
      color: "black",
    },
  },
  taskHeader: {
    padding: "0.5rem",
  },
  taskBody: {
    padding: "0.5rem",
    borderTop: "2px solid #ffffff",
    width: "100%",
  },
  currencySettings: {
    height: "100%",
    background: "#ffffff",
    width: 600,
  },
  hideSettings: {
    display: "none",
  },
  showSettings: {
    display: "block",
  },
};

export default CurrencyConverter;
