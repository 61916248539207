import React, { useEffect, useRef, useState } from "react";
import useCurrencies from "../../../../hooks/useCurrencies";
import StyledCustomTopBarBox from "../../../UI/StyledCustomTopBarBox";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import {
  getFieldByName,
  getInitialValues,
} from "../../../../utils/form_factory";
import FormTextInput from "../../../UI/FormComponents/FormTextInput";
import FormSelectInput from "../../../UI/FormComponents/FormSelectInput";
import { FormFields } from "./models/FormFields";
import { useFormik } from "formik";
import { FormValidations } from "./models/Validations";
import { AllCurrenciesList } from "../../../currency/Currencies";
import ProgressIndicator from "../../../UI/ProgressIndicator";
import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import { figureFormatter } from "../../../../utils/figure_formatter";
import toast from "react-hot-toast";
import { CurrencyRateService } from "../../../../pages/Admin/BankSettings/CurrencySettings/models/services/CurrencyRates.service";

const CurrencyConverterForm = () => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const { currencies } = useCurrencies();
  const [systemRateMode, setSystemRateMode] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [currencyOptions, setCurrencyOptions] = useState<any[]>([]);
  const [result, setResult] = useState<any>(null);
  const [nonFieldErrors, setNonFieldErrors] = useState<any[]>([]);

  const formFields = FormFields(currencyOptions);
  const form = useFormik({
    initialValues: getInitialValues(formFields),
    validationSchema: FormValidations,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: (values) =>
      handleSubmit({ ...values, system_rates: systemRateMode }),
  });

  useEffect(() => {
    if (systemRateMode === true) {
      setCurrencyOptions(currencies);
    } else {
      setCurrencyOptions(AllCurrenciesList);
    }
  }, [systemRateMode]);

  const RateMode = () => {
    return (
      <StyledCustomTopBarBox sx={{ position: "relative-" }}>
        <FormControlLabel
          sx={{
            marginRight: 0,
            "&.MuiTypography-root.MuiFormControlLabel-label": {
              fontSize: 14,
              fontWeight: 500,
            },
          }}
          control={
            <Switch
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSystemRateMode(event.target.checked);
              }}
              checked={systemRateMode}
            />
          }
          label={systemRateMode ? "System Rates" : "Global Rates"}
        />
      </StyledCustomTopBarBox>
    );
  };

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const { converted_amount }: any =
        await CurrencyRateService.convertCurrency(values);
      setResult(converted_amount);
      setLoading(false);
    } catch (error: any) {
      toast.error("Failed to convert currency");

      if (error?.error_type == "non_field_errors") {
        setNonFieldErrors(error?.errors);
      }

      if (error?.response?.data?.errors) {
        form.setErrors(error?.response?.data?.errors);
      }

      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true }),
      );
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", marginBottom: 2 }}>
        <Box sx={{ marginLeft: "auto" }}>
          <RateMode />
        </Box>
      </Box>

      <form
        name={"currency-converter"}
        ref={formRef}
        onSubmit={form.handleSubmit}
      >
        <Box sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item {...getFieldByName(formFields, "amount").uiBreakpoints}>
              <FormTextInput
                {...getFieldByName(formFields, "amount")}
                formControl={form}
              />
            </Grid>
            <Grid
              item
              {...getFieldByName(formFields, "from_currency").uiBreakpoints}
            >
              <FormSelectInput
                {...getFieldByName(formFields, "from_currency")}
                formControl={form}
              />
            </Grid>
            <Grid
              item
              md={1}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <SwapHorizRoundedIcon />
            </Grid>
            <Grid
              item
              {...getFieldByName(formFields, "to_currency").uiBreakpoints}
            >
              <FormSelectInput
                {...getFieldByName(formFields, "to_currency")}
                formControl={form}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ display: "flex", marginTop: 2 }}>
          <Button
            disabled={!form.isValid || loading}
            onClick={handleButtonClick}
            type="submit"
            variant="contained"
          >
            Convert
          </Button>

          <Box
            sx={{
              marginLeft: "auto",
              background: "#ededed",
              width: "65%",
              borderRadius: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 1,
            }}
          >
            <Typography
              sx={{ marginLeft: "auto", fontSize: 15, fontWeight: 800 }}
            >
              {loading ? (
                <>
                  <ProgressIndicator color="inherit" size={20} />{" "}
                </>
              ) : (
                <>
                  {figureFormatter({
                    figure: result,
                    currency: form.values.to_currency,
                  })}
                </>
              )}
            </Typography>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default CurrencyConverterForm;
