import { useContext, useEffect } from "react";
import { CurrenciesContext } from "../contexts/CurrenciesContext";
import CurrenciesStore from "../store/currencies.store";

const useCurrencies = () => {
  let { currencies, baseCurrency, setCurrencies }: any =
    useContext(CurrenciesContext);
  const subscription = CurrenciesStore.subscribe(setCurrencies);
  useEffect(() => {
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  let result: any = {};
  if (currencies?.currencies) {
    currencies = currencies?.currencies;
  }

  if (currencies?.baseCurrency) {
    baseCurrency = currencies?.baseCurrency;
  }

  return { currencies, baseCurrency, setCurrencies };
};

export default useCurrencies;
