import axios from "axios";
import instance from "../../../../../../api";
export const CurrencyRateService = {
  async getCurrencies(filters: Object) {
    return instance
      .get("currency-rates/", {
        params: filters,
      })
      .then((response) => response.data);
  },

  async createCurrency(payload: Object) {
    return instance
      .post("currency-rates/", payload)
      .then((response) => response.data);
  },

  async setDefaultCurrency(payload: Object) {
    return instance
      .post("currency-rates/set-default/", payload)
      .then((response) => response.data);
  },

  async updateCurrency(payload: Object, id: string) {
    return instance
      .patch(`currency-rates/${id}/`, payload)
      .then((response) => response.data);
  },

  async deleteCurrency(id: string) {
    return instance
      .delete(`currency-rates/${id}/`)
      .then((response) => response.data);
  },
  async convertCurrency(payload: Object) {
    return instance
      .post("currency-rates/convert-currency/", payload)
      .then((response) => response.data);
  },
};
