import {
  AppBar,
  Box,
  styled,
  Theme,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { H2 } from "../Typography";
import { FC, useContext } from "react";
import { useAuth } from "../../hooks/useAuth";
import ProfilePopover from "./popovers/ProfilePopover";
import { TitleContext } from "../../contexts/TitleContext";
import WorkflowTaskNotifications from "./popovers/WorkflowTasks";
import NotificationsPopover from "./popovers/NotificationsPopover";
import CurrencyConverter from "./popovers/CurrencyConverter/CurrencyConverter";

interface DashboardNavBarProps {
  setShowMobileSideBar: () => void;
}

const DashboardNavbarRoot = styled(AppBar)(() => ({
  zIndex: 11,
  boxShadow: "none",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  backdropFilter: "blur(6px)",
  backgroundColor: "transparent",
}));

const StyledToolBar = styled(Toolbar)(() => ({
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto",
  },
}));

const ToggleIcon = styled(Box)(({ theme }) => ({
  width: 25,
  height: 3,
  margin: "5px",
  borderRadius: "10px",
  transition: "width 0.3s",
  backgroundColor: theme.palette.primary.main,
}));

const DashboardNavbar: FC<DashboardNavBarProps> = ({
  setShowMobileSideBar,
}) => {
  const { user }: any = useAuth();
  const { title } = useContext(TitleContext);
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <DashboardNavbarRoot position="sticky">
      <StyledToolBar>
        <Box
          sx={{
            cursor: downSm ? "pointer" : "none",
            display: downMd ? "block" : "none",
          }}
          onClick={setShowMobileSideBar}
        >
          <ToggleIcon />
          <ToggleIcon />
          <ToggleIcon />
        </Box>

        {!downSm && (
          <>
            <H2
              fontSize={21}
              lineHeight={0}
              mx={1}
              fontWeight="700"
              color="text.primary"
              marginLeft={0}
            >
              {title}
            </H2>
          </>
        )}

        <Box flexGrow={1} ml={1} />

        <Box display={"flex"} gap={2} alignItems={"center"}>
          <CurrencyConverter />
          <NotificationsPopover />
          {user.type !== "system-user" && <WorkflowTaskNotifications />}
          <ProfilePopover />
        </Box>
      </StyledToolBar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
