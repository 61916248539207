import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { last30DaysDateRange } from "../../../../utils/date_formatter";
import { ICurrency } from "../../../../components/currency/Currencies";

export interface IPaginationState {
  page: number;
  limit: number;
  count: number;
  lastVisitedPage: number;
  paginatedData: {
    data?: any;
    filters?: Record<string, string | number>;
    reportModeStatus?: boolean;
    currency?: ICurrency;
  };
  filters: Record<string, string | number>;
  searchQuery: string;
  reportModeStatus?: boolean;
  currency?: ICurrency;
}

const initialState: IPaginationState = {
  page: 1,
  limit: 10,
  count: 0,
  lastVisitedPage: 1,
  paginatedData: {
    reportModeStatus: false,
    currency: {},
  },
  filters: {
    start_date: last30DaysDateRange()[0],
    end_date: last30DaysDateRange()[1],
  },
  searchQuery: "",
  currency: {},
};

const PaginationSlice = createSlice({
  name: "IncomeStatementReportPagination",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setPaginatedData: (state, action: PayloadAction<{ data: any }>) => {
      const { data }: { data: any } = action.payload;
      state.paginatedData = {
        data,
        filters: state.filters,
        reportModeStatus: state.reportModeStatus,
        currency: state.currency,
      };
    },
    setLastVisitedPage: (state, action: PayloadAction<number>) => {
      state.lastVisitedPage = action.payload;
    },
    clearPaginatedData: (state) => {
      state.paginatedData = {};
    },
    setFilters: (
      state,
      action: PayloadAction<Record<string, string | number>>,
    ) => {
      state.filters = action.payload;
    },
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setReportModeStatus: (state, action: PayloadAction<boolean>) => {
      state.reportModeStatus = action.payload;
    },
    setCurrency: (state, action: PayloadAction<ICurrency>) => {
      state.currency = action.payload;
    },
  },
});

export const {
  setPage,
  setLimit,
  setPaginatedData,
  setLastVisitedPage,
  setCount,
  clearPaginatedData,
  setFilters,
  setSearchQuery,
  setReportModeStatus,
  setCurrency,
} = PaginationSlice.actions;

export const selectPagination = (state: {
  IncomeStatementReportPagination: IPaginationState;
}) => state.IncomeStatementReportPagination;

export default PaginationSlice.reducer;
